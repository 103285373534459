import React from "react";

import logo from "../assets/pulsar_dark_large.png";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3" src={logo} alt="React logo" width="320" />
    <h1 className="mb-4">Annotation Web Authentication</h1>
    <p className="lead">
      This is an application to provide users with credentials to access our
      annotation platform.
    </p>
  </div>
);

export default Hero;
