import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner
} from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0 } from "../react-auth0-spa";

async function fetchPassword(token) {
  const results = await fetch(
    `${process.env.REACT_APP_API_URL}/permissions/createUser`,
    {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return await results.json();
}

const SuccessModalContent = ({ toggle, password }) => {
  let textInput = null;
  const copyCodeToClipboard = () => {
    const element = textInput;
    element.select();
    document.execCommand("copy");
  };
  return (
    <>
      <ModalHeader toggle={toggle}>Password</ModalHeader>
      <ModalBody>
        <p>
          Here is your newly generated password, unless you forget your password
          there's no need to create a new one. This new password might take up
          to a day to be activated
        </p>
        <Input
          innerRef={input => {
            textInput = input;
          }}
          value={password}
          readOnly
        />
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={copyCodeToClipboard}>
          Copy to clipboard
        </Button>
        <Button color="danger" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};

const FailModalContent = ({ toggle }) => {
  return (
    <>
      <ModalHeader toggle={toggle}>Error</ModalHeader>
      <ModalBody>
        <p>
          There was en error processing your request, please try again later
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};
const Profile = () => {
  const [password, setPassword] = useState();
  const [modal, setModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const toggle = () => setModal(!modal);

  const { loading, user, getIdTokenClaims } = useAuth0();
  if (loading || !user) {
    return <Loading />;
  }

  async function handlePasswordRequest() {
    setDisabled(true);
    const token = await getIdTokenClaims();
    try {
      const results = await fetchPassword(token.__raw);
      setPassword(results.password);
      setModal(true);
      setDisabled(false);
    } catch (e) {
      setModal(true);
      setDisabled(false);
    }
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Row className="mb-5" style={{ alignItems: "center" }}>
        <Button disabled={disabled} onClick={handlePasswordRequest}>
          Get new password
        </Button>
        {disabled && (
          <Spinner color="primary" size="sm" style={{ marginLeft: "1em" }} />
        )}
      </Row>
      <Modal isOpen={modal} toggle={toggle}>
        {password ? (
          <SuccessModalContent toggle={toggle} password={password} />
        ) : (
          <FailModalContent toggle={toggle} />
        )}
      </Modal>
    </Container>
  );
};

export default Profile;
